export class CrachaModel {
    
    nome: string = '';
    cursoCracha: string = '';
    pessoas: PessoaModel[] = [];

    constructor(model?: CrachaModel) {

        if (!model) {
            return;
        }

        this.nome = model.nome;
        this.cursoCracha = model.cursoCracha;
        this.pessoas = model.pessoas

    }
}

export class PessoaModel{

    nome: string = '';
    nomeCracha: string = '';

    constructor(model?: PessoaModel){

        if(!model)
            return;

        this.nome = model.nome;
        this.nomeCracha = model.nomeCracha;
    }

}