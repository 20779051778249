





























import { CrachaModel } from "@/core/models/models/CrachaModel";
import { PageBase } from "@/core/models/shared";
import { TurmaService } from "@/core/services/geral";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ConfiguracaoCracha extends PageBase {

    item = new CrachaModel();
    turmaService = new TurmaService();
    background: boolean = false;
    filtro: any = {
        turmaId: 0,
        fundo: true,
        idAlunos: "",
        idUsuario: ""
    }
    overlay: boolean = false;
    root: any = null;

    userCardTop: number = 0;
    userCardFont: number = 0;
    marginTop: number = 0;

    mounted() {

        this.root = getComputedStyle(document.body);

        this.filtro = {
            turmaId: this.$route.query.turmaId,
            fundo: this.$route.query.fundo == "true" ? true : false,
            idAlunos: this.$route.query.idAlunos,
            idUsuario: this.$route.query.idUsuario
        }

        this.Carregar();

        this.LerLayout();
    }

    LerLayout(){
        this.userCardTop = parseInt(this.root.getPropertyValue('--userCardTop').replace(/\D/g, ""));
        this.userCardFont = parseInt(this.root.getPropertyValue('--userCardFont').replace(/\D/g, ""));
        this.marginTop = parseFloat(this.root.getPropertyValue('--marginTop').replace("cm", ""));
    }

    CarregarLayout(){

        document.documentElement.style.setProperty("--userCardTop", this.userCardTop + 'px')
        document.documentElement.style.setProperty("--userCardFont", this.userCardFont + 'px')
        document.documentElement.style.setProperty("--marginTop", this.marginTop + 'cm')
    }

    Carregar(){

        this.overlay = true;

        this.turmaService.GerarCracha(this.filtro.turmaId, this.filtro.fundo, this.filtro.idAlunos, this.filtro.idUsuario).then(
            res =>{
                this.item = res.data;
            },
            err =>{
                this.$swal('Aviso',err.response.data,'error')
            }
        ).finally(() => {
            this.overlay = false;
        })
    }

}
